<template>
  <div class="page_root" id="root_demo">
    <div class="warp">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="card_ad">
              <!-- 页头 -->
              <list_ad
                  :list="list_ad"
                  location="店招"
                  v-if="$check_action('/ad/list', 'get')"
                  style="marginbottom: 0.5rem"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="warp">
      <div class="container swiper_box">
        <div class="row">
          <div class="col-12">
            <div class="sw_no">
              <!-- 轮播图组件 -->
              <div class="swiper_img">
                <swiper_img :list="list_slide"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="number" v-on:mouseenter="onMouseEnter">
      <div class="numtitle">

      </div>
      <div id="box">
        <span class="numnote">每年售出</span>
        <div class="rodnum" style="width: 32%">
          <animate-number from="0" :to="36000000" ref="myNum2"></animate-number>
          <span style="color: black">+</span>
        </div>
        <span class="numnote">个肉夹馍</span>
      </div>
      <div id="box">
        <span class="numnote1">截至2024年1月1日，徐志馍门店数量已达</span>
        <div class="rodnum1">
          <animate-number from="0" :to="500" ref="myNum"></animate-number>
          <span style="color: black">+</span>
        </div>
        <span class="numnote1">家&nbsp;&nbsp;</span>

        <span class="numnote1">线上注册会员数</span>
        <div class="rodnum1">
          <animate-number from="0" :to="1000000" ref="myNum1"></animate-number>
          <span style="color: black">+</span>
        </div>
      </div>

    </div>

    <div class="warp" >
      <!-- 容器 -->
      <div class="container">
        <div class="row">
          <div class="col-12">
            <!-- 标题栏组件 -->
            <div class="article_recommend">
              <div class="title">
                <span class="ppzx"> 品牌资讯 </span>
              </div>
              <div class="more_box">
                <router-link to="/article/list" class="more">
                  <span>更多</span>
                </router-link>
              </div>
              <div class="switch" @click="show_list_article = !show_list_article">
                <div class="switch_box">
                  <span v-if="show_list_article"> 切换表格 </span>
                  <span v-else> 切换列表 </span>
                </div>
              </div>
            </div>
            <!-- 品牌资讯列表组件 -->
            <list_article v-if="show_list_article" :list="list_article"/>
            <div class="overflow-auto" v-else>
              <table id="list_article" role="table" aria-busy="false"
                     :aria-colcount="fields_article.length"
                     class="table b-table table-striped table-hover">
                <thead>
                <tr>
                  <th v-for="(o,i) in fields_article" :key="i">
                    {{ o.label }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(o, i) in list_table_article" :key="i">
                  <td v-for="(oj,n) in fields_goods" @click="to_details('goods',o)">
                    <img v-if="oj.type && oj.type == '图片' " :src="$fullimgUrl(o[oj.key])" alt=""
                         v-default-img="'/img/default.png'">
                    <span v-else>{{ o[oj.key] }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!--    <div class="commentshow">-->
    <!--      <div class="container swiper_box">-->
    <!--        <div class="row">-->
    <!--          <div class="col-12">-->
    <!--            <div class="sw_no">-->
    <!--              <div class="card_notice">-->
    <!--                <div class="notice_title">评论展示-->
    <!--                </div>-->
    <!--                <router-link to="notice/list">更多</router-link>-->
    <!--                &lt;!&ndash; 公告组件 &ndash;&gt;-->
    <!--                <swiper_notice :list="list_notice"/>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="zkcf">
      <div class="article_recommend">
        <div >
          <span style="font-weight: bolder;font-size: xx-large"> 百万合作商专刊 </span>
        </div>
      </div>
      <div class="bfq">
        <div class="player1">
          <video
              src="../assets/video/video1.mp4"
              style="width: 100%;height: 100%;object-fit: fill"
              controls=”controls”
              poster="../assets/video/video1.jpg"
          >
          </video>
        </div>
        <div class="player2">
          <video
              src="../assets/video/video2.mp4"
              style="width: 100%;height: 100%;object-fit: fill"
              controls=”controls”
              poster="../assets/video/video2.jpg"
          >
          </video>
        </div>
        <div class="player2">
          <video
              src="../assets/video/video3.mp4"
              style="width: 100%;height: 100%;object-fit: fill"
              controls=”controls”
              poster="../assets/video/video3.jpg"
          >
          </video>
        </div>
      </div>

    </div>

    <div class="to_xzm">
      <div class="to_xzm_bg">
      </div>
      <div class="xzm">
        <router-link to="notice/list">走进徐志馍>></router-link>
      </div>
    </div>
    <div class="jmly">
      <router-link to="/consultation_information/edit">
        <div class="jmly_to"></div>
      </router-link>
    </div>
    <el-dialog :visible.sync="dialogTableVisible">
      <div class="dialog_content"></div>
    </el-dialog>
  </div>
</template>

<script>
import mixin from "@/mixins/page.js";
import list_home_stay_information from "@/components/diy/list_home_stay_information.vue";
import bar_title from "@/components/diy/bar_title.vue";
import list_article from "@/components/diy/list_article.vue";
import swiper_img from "@/components/diy/swiper_img.vue";
import list_ad from "@/components/diy/list_ad.vue";
import list_link from "@/components/diy/list_link.vue";
import swiper_notice from "@/components/diy/swiper_notice.vue";

export default {
  mixins: [mixin],
  components: {
    list_home_stay_information,
    bar_title,
    list_article,
    swiper_img,
    swiper_notice,
    list_ad,
    list_link,
  },
  data() {
    return {
      isSmall: false,
      sendValue: "",
      chatList: [],
      showChat: false,
      isAdmin: false,
      token: "",
      // 文章模型数组
      list_article: [],
      vm_arr_article: [
        "img",
        "title",
        "description",
        "hits",
        "type",
        "tag",
        "source",
        "url",
        "create_time",
      ],
      fields_article: [
        {key: "img", label: "图片"},
        {key: "title", label: "标题"},
        {key: "description", label: "描述"},
        {key: "hits", label: "点击量"},
        {key: "type", label: "分类"},
        {key: "tag", label: "标签"},
        {key: "source", label: "来源"},
        {key: "url", label: "来源地址"},
        {key: "create_time", label: "发布时间"},
      ],
      show_list_article: true,
      show_list_home_stay_information: true,
      list_home_stay_information: [],
      vm_arr_home_stay_information: [
        "home_stay_information_id",
        "management_no",
        "name_of_home_stay",
        "home_stay_cover",
        "room_type",
        "home_stay_specifications",
        "room_price",
        "number_of_rooms",
        "home_stay_details",
        "merchant_users",
        "merchant_name",
        "merchant_address",
      ],
      list_slide: [],
      list_menu: [],
      list_link: [],
      list_notice: [],
      list_ad: [],
      dialogTableVisible:false,
    };
  },
  created() {
    this.get_token();
    this.dialogTableVisible = true;
  },
  methods: {
    get_token() {
      let token = this.$route.query.token
      if (token) {
        $.db.set("token", token, 120);
        location.href = "http://localhost:8081/"
      }
    },
    // toggle
    toToggle() {
      this.isAdmin = !this.isAdmin;
    },

    // 获取产品信息列表
    get_home_stay_information() {
      let url = "~/api/home_stay_information/get_list?";
      // url = "~/api/home_stay_information/get_list?orderby=hits desc";
      this.$get(url, {
        "orderby": "hits desc",
        "page": 1,
        "size": 8
      }, (json) => {
        if (json.result) {
          this.list_home_stay_information = json.result.list;
        }
      })
    },

    /**
     * 当前年月日时分秒方法
     * @param {Object} fmt
     */
    dateFormat(fmt) {
      var myDate = new Date();
      var o = {
        "M+": myDate.getMonth() + 1, // 月份
        "d+": myDate.getDate(), // 日
        "h+": myDate.getHours(), // 小时
        "m+": myDate.getMinutes(), // 分
        "s+": myDate.getSeconds(), // 秒
        "q+": Math.floor((myDate.getMonth() + 3) / 3), // 季度
        "S": myDate.getMilliseconds() // 毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (myDate.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
    },

    // 获取轮播图
    get_slides() {
      this.$get("~/api/slides/get_list?", {}, (json) => {
        if (json.result) {
          this.list_slide = json.result.list;
        }
      });
    },
    // 获取导航栏
    get_menu() {
      var user_group = this.user_group;
      this.$get(
          "~/api/auth/get_list?",
          {
            page: "",
            user_group,
            get: 1,
            position: "top"
          },
          (json) => {
            if (json.result) {
              this.list_menu = json.result.list;
            }
          }
      );
    },
    // 获取文章
    get_article() {
      this.$get(
          "~/api/article/get_list?",
          {
            page: 1,
            size: 4,
            orderby: "article_id desc"
          },
          (json) => {
            if (json.result) {
              var list_article = json.result.list;
              this.list_article = list_article;
            }
          }
      );
    },
    // 获取广告
    get_ad() {
      this.$get(
          "~/api/ad/get_list?",
          {
            orderby: '`display` desc'
          },
          (json) => {
            if (json.result) {
              var list_ad = json.result.list;
              this.list_ad = list_ad;
            }
          }
      );
    },
    // 获取链接列表
    get_link() {
      this.$get(
          "~/api/link/get_list?",
          {
            page: 1,
            size: 8,
          },
          (json) => {
            if (json.result) {
              var list_link = json.result.list;
              this.list_link = list_link;
            }
          }
      );
    },
    // 获取公告列表
    get_notice() {
      this.$get(
          "~/api/notice/get_list?",
          {
            page: 1,
            size: 3,
          },
          (json) => {
            if (json.result) {
              var list_notice = json.result.list;
              this.list_notice = list_notice;
            }
          }
      );
    },
    to_details(key, o, id) {
      if (!id) {
        id = key + "_id";
      }
      this.$router.push('/' + key + '/details?' + id + '=' + o[id]);
    },
    onMouseEnter() {
      this.$refs.myNum.start()
      this.$refs.myNum1.start()
      this.$refs.myNum2.start()
    },
  },
  mounted() {
    this.get_home_stay_information();
    this.get_menu();
    this.get_slides();
    this.get_article();
    // this.get_link();
    this.get_notice();
    // this.get_ad();
  },
  computed: {
    list_table_consultation_information() {
      var list = this.list_home_stay_information;
      var list_table = [];
      for (let i = 0; i < list.length; i++) {
        list_table[i] = {};
        this.vm_arr_home_stay_information.map((o) => {
          // 第二个中括号是对象的属性
          list_table[i][o] = list[i][o] || "";
        });
      }
      return list_table;
    },


    list_table_article() {
      var list = this.list_article;
      var list_table = [];
      for (let i = 0; i < list.length; i++) {
        list_table[i] = {};
        this.vm_arr_article.map((o) => {
          // 第二个中括号是对象的属性
          list_table[i][o] = list[i][o] || "";
        });
      }
      return list_table;
    },
  }
};
</script>

<style scoped>
.page_root {
  position: relative;
  background-image: url("../assets/images/numbg.jpg");
  background-repeat: round;
}

.support_entry {
  position: fixed;
  top: 400px;
  right: 20px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 99999;
}

.support_module .container {
  /* height: 650px; */
  height: 70%;
  width: 700px;
  border-radius: 4px;
  border: 0.5px solid #e0e0e0;
  background-color: #f5f5f5;
  /* display: flex;
  flex-flow: column; */
  overflow: hidden;
  position: fixed;
  /* top: 100px; */
  padding: 0;
  margin-left: -350px;
  z-index: 99999999;
}

.support_module .small_css {
  height: 40px;
  width: 200px;
  right: 0;
  bottom: 0px;
}

.support_module .big_css {
  top: 60px;
  left: 50%;
}

.support_module .content {
  width: calc(100% - 0px);
  padding: 20px 30px;
  overflow-y: scroll;
  height: 70%;
}

.support_module .content::-webkit-scrollbar {
  display: none
}

.support_module_title {
  text-align: right;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ccc;

}

.support_module_title .title_btn {
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
  color: #888;

}

.support_module .content:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
}

.support_module .bubble {
  max-width: 400px;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  color: #000;
  word-wrap: break-word;
  word-break: normal;
}

.support_module .item_left .bubble {
  margin-left: 15px;
  background-color: #fff;
}

.support_module .item_left .bubble:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-right: 10px solid #fff;
  border-bottom: 10px solid transparent;
  left: -20px;
}

.support_module .item_right .bubble {
  margin-right: 15px;
  background-color: #9eea6a;
}

.support_module .item_right .bubble:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid #9eea6a;
  border-top: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: none;

  right: -20px;
}

.support_module .item {
  margin-top: 15px;
  display: flex;
  width: 100%;
}

.support_module .item.item_right {
  justify-content: flex-end;
  min-width: 400px;
}

.support_module .item.item-center {
  justify-content: center;
}

.support_module .item.item-center span {
  font-size: 12px;
  padding: 2px 4px;
  color: #fff;
  background-color: #dadada;
  border-radius: 3px;
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}

.support_module .avatar img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.support_module .input-area {
  border-top: 0.5px solid #e0e0e0;
  height: 16%;

  background-color: #fff;
}

.support_module textarea {
  flex: 1;
  padding: 10px;
  font-size: 14px;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  resize: none;
  width: 102%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.support_module .button-area {
  display: flex;
  height: 50px;
  line-height: 50px;
  padding: 5px;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
  background: #fff;
}

.support_module .button-area button {
  width: 80px;
  height: 40px;
  line-height: 40px;
  border: none;
  outline: none;
  border-radius: 4px;
  float: right;
  cursor: pointer;
  background: #9eea6a;
  color: #333;
  margin: 0px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 10px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

.sw_no {
  height: 100vh;
  margin: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.swiper_img {
  width: 60%;
  height: 18.75rem;
}

.card_notice {
  width: 40%;
  padding-left: 1rem;
  height: 18.75rem;
}

.notice_title {
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d2d2d2;
  height: 3rem;
  padding: 0 1.5rem;
}

.article_recommend > * {
  display: flex;
  justify-content: center;
  align-items: center;
}

.article_recommend {
  display: block;
  margin: 1.5rem 0.5rem;
  height: 4rem;
  border-radius: 0.5rem;
  background-color: var(--color_primary);
}

.diy_recommend > * {
  display: flex;
  justify-content: center;
  align-items: center;
}

.diy_recommend {
  display: block;
  margin: 1.5rem 0.5rem;
  height: 4rem;
  border-radius: 0.5rem;
  background-color: var(--color_primary);
}

.title {
  float: left;
  height: 4rem;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 1rem;
}

.more_box {
  float: right;
  height: 4rem;
  color: white;
  font-size: 1.5rem;
  padding-right: 1rem;
}

.more_box .more {
  border-radius: 0.5rem;
  border: 0.125rem solid white;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: center;
  height: 3rem;
  width: 3.5rem;
}

.switch {
  float: right;
  height: 4rem;
  color: white;
  padding-right: 1rem;
}

.switch_box {
  border-radius: 0.5rem;
  border: 0.125rem solid white;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  justify-content: center;
  height: 3rem;
  width: 6rem;
}

.switch_box:hover, .more:hover {
  background-color: #ffffff;
  color: var(--color_primary) !important;
}

.overflow-auto .table th,
.overflow-auto .table td {
  text-align: center;
  text-wrap: normal;
  font-size: 1rem;
  vertical-align: middle;
}

/*.page_index {*/
/*    margin: 0 auto;*/
/*    max-width: 1200px;*/
/*}*/

/*.main {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/

/*.content {*/
/*    flex: 0 0 79%;*/
/*}*/

/*.aside {*/
/*    flex: 0 0 20%;*/
/*}*/

.card_ad {
  margin-top: 1rem;
}

.number {
  width: 100%;
  height: 60vh;
  //margin-top: -6vh;
  //background: url("../assets/images/allbg.jpg") no-repeat;
  //background-size: 100% 100%;
  //background-color: rgb(195, 21, 44);
}

.number .numtitle {
  width: 30%;
  height: 26%;
  margin-top: 20px;
  margin-left: 35%;
  background: url("../assets/images/logo.png") no-repeat;
  background-size: 100% 100%;
}

#box {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.number .rodnum {
  color: red;
  width: 12%;
  height: 20%;
  font-family: KaiTi;
  font-size: 7vw;
  font-weight: bolder;
}

.number .numnote {
  color: #000000;
  height: 20%;
  font-family: "微软雅黑";
  font-size: 4vw;
  font-weight: bolder;
}

.number .rodnum1 {
  color: red;
  height: 20%;
  font-family: KaiTi;
  font-size: 5vw;
  font-weight: bolder;
}

.number .numnote1 {
  color: #000000;
  height: 20%;
  font-family: "微软雅黑";
  font-size: 2vw;
  font-weight: bolder;
}

.commentshow {
  height: 50vh;
  clear: none;
}

.zkcf {
  width: 73.5%;
  height: 910px;
  margin: 0 auto;
  margin-top: 84px;
  margin-bottom: 35px;
}

.zkcf .bfq {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.zkcf .player1 {
  width: 99%;
  height: 535px;
  margin-bottom: -70px;
}

.zkcf .player2 {
  width: 49.5%;
  height: 350px;
  padding: 5px 5px 0 0;
}

.to_xzm {
  width: 100%;
  height: 630px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;

}
.to_xzm_bg {
  width: 100%;
  height: 100%;
  background: url("../assets/images/zjbg.jpg") no-repeat;
  opacity: 0.6;
  background-size: 100% 100%;
}

.to_xzm .xzm {
  width: 20%;
  height: 1.8%;
  background-color: #e70012;
  color: #FFFFFF;
  font-family: "微软雅黑";
  font-size: 2.5vw;
  text-align: center;
  margin-top: 58vh;
  margin-left: 10%;
  font-weight: bolder;
  position: absolute;
}
.jmly {
  height: 68px !important;
  width: 130px;
  z-index: 11111112;
  top: 20%;
  right: 0px;
  position: fixed;
  margin-top: -34px;
  margin-left: 0px;
}
.jmly_to {
  height: 100%;
  width: 100%;
  background: url("../assets/images/join/hzzx.png") no-repeat;
  background-size: 100% 100%;
}
/deep/ .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: url("../assets/images/numbg.jpg")no-repeat;
  background-size: 100% 100%;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgb(190, 190, 190);
  box-sizing: border-box;
  width: 50%;
}
/deep/ .el-dialog__header {
  padding: 0px;
}
/deep/ .el-dialog__body {
  padding: 0px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.dialog_content {
  width: 100%;
  min-height: 500px;
  background: url("../assets/images/ppsm.png")no-repeat;
  background-size: 100% 100%;
}
.ppzx {
  font-weight: bolder;
  font-size: xx-large
}

@media (max-width: 1081px) {
  .swiper_img {
    width: 100%;
    height: 12.5rem;
  }

  .card_notice {
    width: 100%;
    padding-left: 0;
  }
  .jmly_to {
    height: 50%;
    width: 50%;
    background: url("../assets/images/join/hzzx.png") no-repeat;
    background-size: 100% 100%;
  }
  .sw_no {
    height: 50vh;
    margin: 0.5rem;
    display: flex;
    flex-wrap: wrap;
  }
  .number[data-v-a83bd3b0] {
    width: 100%;
    height: 15vh;
  }
  .number .numtitle {
    width: 50%;
    height: 30%;
    /* margin-top: 20px; */
    /* margin-left: 35%; */
    margin: auto;
    background: url("../assets/images/logo.png") no-repeat;
    background-size: 100% 100%;
  }
  .number .numnote1 {
    color: #000000;
    height: 20%;
    font-family: "微软雅黑";
    font-size: 2.3vw;
    font-weight: bolder;
  }
  #box {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-right: 10px;
  }
  #root_demo .article_recommend .title::before {
    position: absolute;
    content: '';
    width: 105px;
    border: 2px solid #e70012;
    border-radius: 2px;
    bottom: 0;
    /* left: 20%; */
  }
  #root_demo .article_recommend, #root_demo .diy_recommend {
    background: #FFFFFF !important;
    margin-bottom: 0;
    border-radius: 0px;
    position: relative;
    margin: auto;
  }
  .ppzx {
    font-weight: bolder;
    font-size: x-large
  }
  #root_demo .article_recommend .more_box .more, #root_demo .diy_recommend .more_box .more {
    font-size: 16px;
    position: absolute;
    right: 12px;
    top: 20px;
    background: #c3152c;
    border-radius: 10px;
    /* padding: 0 20px; */
    height: 22px;
    line-height: 13px;
    color: #fff !important;
  }
  .jmly {
    height: 68px !important;
    width: 130px;
    z-index: 11111112;
    top: 20%;
    right: -45px;
    position: fixed;
    margin-top: -34px;
    margin-left: 0px;
  }
  .zkcf .player1{
    width: 99%;
    height: 30vh;
    margin-bottom: 1px;
  }
  .zkcf .player2{
    width: 100%;
    height: 30vh;
    padding: 5px 5px 0 0;
  }
  .zkcf .bfq{
    width: 100%;
    height: 69%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .zkcf {
    width: 73.5%;
    height: 100vh;
    margin: 0 auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
  .to_xzm {
    width: 100%;
    height: 20vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
  .to_xzm .xzm{
    width: 18%;
    height: 0.7%;
    background-color: #e70012;
    color: #FFFFFF;
    font-family: "微软雅黑";
    font-size: 2.5vw;
    text-align: center;
    margin-top: 12vh;
    margin-left: 10%;
    font-weight: bolder;
    position: absolute;
  }
  /deep/ .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: url("../assets/images/numbg.jpg") no-repeat;
    background-size: 100% 100%;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgb(190, 190, 190);
    box-sizing: border-box;
    width: 100%;
  }
  /deep/ .el-dialog__header {
    padding: 0px;
  }
  /deep/ .el-dialog__body {
    color: #606266;
    font-size: 14px;
    word-break: break-all;
  }
  .dialog_content {
    width: 100%;
    min-height: 500px;
    background: url("../assets/images/ppsm2.png")no-repeat;
    background-size: 100% 100%;
  }

}
</style>
