<template>
  <footer class="page_footer">
    <div class="wrap">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="card_copyright">
              <img src="@/assets/images/logow.png" class="dblogo" ><br><br>
              <span>山东徐志馍餐饮管理有限公司</span>
              <span>商务合作&nbsp;丨&nbsp;www.zgxuzhimo.com</span>
              <span>全国服务热线&nbsp;丨&nbsp;400-0068-685</span><br><br>
              <span>版权归山东徐志馍餐饮管理有限公司所有&nbsp;&nbsp;<a href="https://beian.miit.gov.cn">备案号:鲁ICP备2022010160号-1</a>&nbsp;&nbsp;*投资有风险 加盟需谨慎</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import mixin from "@/mixins/component.js";
// import Image from "@/views/media/image.vue";
export default {
  mixins: [mixin],
  components: {},
  data() {
    return {
    };
  },
  methods: {},
  created() {},
};
</script>

<style scoped="scoped">

.page_footer .wrap {
  color: white;
}
.card_copyright {
	padding: 30px 10px;
  text-align: center;
  overflow: hidden;
  font-size: smaller;
}
.card_copyright > span {
  margin-right: 50px;
  color: rgba(222, 222, 222, 0.8);
}
.dblogo {
  width: 15%;
  height: 100%;
  object-fit: cover
}
@media (max-width: 1081px) {
  .card_copyright {
    padding: 5px 5px;
    text-align: center;
    overflow: hidden;
    font-size: smaller;
  }
  .dblogo {
    width: 30%;
    height: 100%;
    object-fit: cover
  }
  .card_copyright > span {
    margin: 10px;
    color: rgba(222, 222, 222, 0.8);
  }
}
</style>
