<template>
  <div class="diy_home diy_list diy_home_stay_information" id="diy_home_stay_information_list">
    <!-- 列表 -->
    <div class="diy_view_list list list-x">
      <div class="diy_card goods diy_list_box_wrap" v-for="(o, i) in list" :key="i">
        <!--        <router-link class="diy_card goods diy_list_box_wrap" v-for="(o, i) in list" :key="i"-->
        <!--                     :to="'/home_stay_information/details?home_stay_information_id=' + o['home_stay_information_id']">-->
        <!-- 图片 -->
        <div class="diy_list_img_box" v-if="imgList.length">
          <div class="diy_row" v-for="(item,index) in imgList" :key="item+index"
               v-show="$check_field('get',item.name,'/home_stay_information/details') && +item.is_img_list">
            <div class="diy_title diy_list_img_title">
              <span>{{ item.title }}:</span>
            </div>
            <div class="diy_field diy_img">
              <img :src="$fullimgUrl(o[item.name])" style="width:100%;height:100%"/>
            </div>
            <div class="card_name"><span>{{ o.name_of_home_stay }}</span></div>
          </div>
        </div>
        <!-- 内容 -->
        <!--        <div class="diy_list_item_box">-->
        <!--          <div class="diy_list_item_content" v-for="(item,index) in showItemList" :key="item+index">-->
        <!--            <div class="diy_row" :class="{[item.name]:true}"-->
        <!--                 v-if="$check_field('get',item.name,'/home_stay_information/details') && +item.is_img_list">-->
        <!--              <div class="diy_title">-->
        <!--                <span>{{ item.title }}:</span>-->
        <!--              </div>-->
        <!--              <div class="diy_field diy_text">-->
        <!--                <span v-if="item.type == 'UID'" v-text="get_user_name(o[item.name])"></span>-->
        <!--                <span v-else-if="item.type == '日期'" v-text="$toTime(o[item.name],'yyyy-MM-dd')"></span>-->
        <!--                <span v-else-if="item.type == '时间'" v-text="$toTime(o[item.name],'hh:mm:ss')"></span>-->
        <!--                <span v-else-if="item.type == '日长'" v-text="$toTime(o[item.name],'yyyy-MM-dd hh:mm:ss')"></span>-->
        <!--                <span v-else v-text="o[item.name]"></span>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        </router-link>-->
      </div>
    </div>
    <!-- 表格 -->
    <!--    <div class="diy_view_table" v-else>-->
    <!--      <table class="diy_table">-->
    <!--        <tr class="diy_row">-->
    <!--          <th class="diy_title" v-if="$check_field('get','management_no')">-->
    <!--            管理编号-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','name_of_home_stay')">-->
    <!--            民宿名称-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','home_stay_cover')">-->
    <!--            民宿封面-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','room_type')">-->
    <!--            房间类型-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','home_stay_specifications')">-->
    <!--            民宿规格-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','room_price')">-->
    <!--            房间单价-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','number_of_rooms')">-->
    <!--            房间数量-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','merchant_users')">-->
    <!--            商家用户-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','merchant_name')">-->
    <!--            商家名称-->
    <!--          </th>-->
    <!--          <th class="diy_title" v-if="$check_field('get','merchant_address')">-->
    <!--            商家地址-->
    <!--          </th>-->
    <!--        </tr>-->
    <!--        <tr class="diy_row" v-for="(o,i) in list" :key="o+i">-->
    <!--          <td class="diy_field diy_text" v-if="$check_field('get','management_no')">-->
    <!--						<span>-->
    <!--							{{ o["management_no"] }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--          <td class="diy_field diy_text" v-if="$check_field('get','name_of_home_stay')">-->
    <!--						<span>-->
    <!--							{{ o["name_of_home_stay"] }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--          <td class="diy_field" v-if="$check_field('get','home_stay_cover')">-->
    <!--            <img class="diy_img" :src="o['home_stay_cover']"/>-->
    <!--          </td>-->
    <!--          <td class="diy_field diy_text" v-if="$check_field('get','room_type')">-->
    <!--						<span>-->
    <!--							{{ o["room_type"] }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--          <td class="diy_field diy_text" v-if="$check_field('get','home_stay_specifications')">-->
    <!--						<span>-->
    <!--							{{ o["home_stay_specifications"] }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--          <td class="diy_field diy_number" v-if="$check_field('get','room_price')">-->
    <!--						<span>-->
    <!--							{{ o["room_price"] }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--          <td class="diy_field diy_number" v-if="$check_field('get','number_of_rooms')">-->
    <!--						<span>-->
    <!--							{{ o["number_of_rooms"] }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--          <td class="diy_field diy_uid" v-if="$check_field('get','merchant_users')">-->
    <!--						<span>-->
    <!--							{{ get_user_name(o['merchant_users']) }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--          <td class="diy_field diy_text" v-if="$check_field('get','merchant_name')">-->
    <!--						<span>-->
    <!--							{{ o["merchant_name"] }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--          <td class="diy_field diy_text" v-if="$check_field('get','merchant_address')">-->
    <!--						<span>-->
    <!--							{{ o["merchant_address"] }}-->
    <!--						</span>-->
    <!--          </td>-->
    <!--        </tr>-->
    <!--      </table>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    show: {
      type: Boolean,
      default: function () {
        return true;
      }
    }
  },
  data() {
    return {
      imgList: [
        {
          title: "产品封面",
          name: "home_stay_cover",
          type: "图片",
          is_img_list: "1"
        },
      ],
      itemList: [
        {
          title: "管理编号",
          name: "management_no",
          type: "文本",
          is_img_list: "0"
        },
        {
          title: "民宿名称",
          name: "name_of_home_stay",
          type: "文本",
          is_img_list: "1"
        },
        {
          title: "房间类型",
          name: "room_type",
          type: "下寻",
          is_img_list: "0"
        },
        {
          title: "民宿规格",
          name: "home_stay_specifications",
          type: "文本",
          is_img_list: "1"
        },
        {
          title: "房间单价",
          name: "room_price",
          type: "数字",
          is_img_list: "1"
        },
        {
          title: "房间数量",
          name: "number_of_rooms",
          type: "数字",
          is_img_list: "1"
        },
        {
          title: "商家用户",
          name: "merchant_users",
          type: "UID",
          is_img_list: "0"
        },
        {
          title: "商家名称",
          name: "merchant_name",
          type: "文本",
          is_img_list: "0"
        },
        {
          title: "商家地址",
          name: "merchant_address",
          type: "文本",
          is_img_list: "0"
        },
      ],
      richList: [
        {
          title: "民宿详情",
          name: "home_stay_details",
          type: "编辑"
        },
      ],
      // 用户列表
      list_user_merchant_users: [],
    };
  },
  methods: {
    /**
     * 获取商家用户用户列表
     */
    async get_list_user_merchant_users() {
      var json = await this.$get("~/api/user/get_list?user_group=商家用户");
      if (json.result && json.result.list) {
        this.list_user_merchant_users = json.result.list;
      } else if (json.error) {
        console.error(json.error);
      }
    },
    get_user_name(id) {
      var obj = this.list_user_merchant_users.getObj({"user_id": id});
      var ret = "";
      if (obj) {
        ret = obj.nickname + "-" + obj.username;
        // if(obj.nickname){
        // 	ret = obj.nickname;
        // }
        // else{
        // 	ret = obj.username;
        // }
      }
      return ret;
    },
  },
  created() {
    this.get_list_user_merchant_users();
  },
  computed: {
    showItemList() {
      let arr = [];
      let _type = ["视频", "音频", "文件"];
      this.itemList.forEach(item => {
        if (_type.indexOf(item.type) === -1 && !!+item.is_img_list) {
          arr.push(item)
        }
      })
      return arr.slice(0, 4);
    }
  }
};
</script>

<style scoped>
.media {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 75%;
  min-height: 10rem;
}

.goods {
  display: flex;
  width: calc(25% - 1rem);
  margin: 0.5rem;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 0.5rem;
}

.goods:hover {
  border: 0.2rem solid rgb(255, 108, 108);
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
}

.goods:hover img {
  filter: blur(0.5px);
}

.price {
  font-size: 1rem;
  margin-right: 3px;
}

.price_ago {
  text-decoration: line-through;
  font-size: 0.5rem;
  color: #999;

}

.title {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  padding: .25rem;
}

.icon_cart {
  color: #FF5722;
  float: right;
}

.card_name {
  color: black;
  font-weight: bolder;
  text-align: center;
}

@media (max-width: 992px) {

  .goods {
    width: calc(33% - 1rem);;
  }

}

@media (max-width: 1081px) {

  .goods {
    width: calc(100% - 1rem);;
  }

}
</style>

