<template>
  <div class="page_header">
    <transition name="fade">
      <div v-show="visible" class="header_warp">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="my_header">
                <router-link to="/">
                  <div class="logo"></div>
                </router-link>
                <div ref="nav_target" class="nav_list">
                  <ul>
                    <li>
                      <router-link to="/">
                        首页
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/notice/list">
                        关于徐志馍
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/article/list">
                        {{ $page_title("/article/list") || "新闻通知" }}
                      </router-link>
                    </li>
                    <li v-if="nav_top && i < top_max" v-for="(o,i) in nav_top" v-bind:key="i">
                      <router-link :to="o.path">
                        {{ o.page_title }}
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/join/list">
                        合作专区
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/recruitment/list">
                        加入我们
                      </router-link>
                    </li>
                    <li>
                      <router-link to="/connection/list">
                        联系我们
                      </router-link>
                    </li>
                    <li v-if="nav_top.length > top_max">
                      <div ref="more" class="more" @click="change()">
                        <span class="more_text">更多</span>
                        <span class="caret" v-bind:style="
												{transform: 'rotate('+ angle1 +'deg)'}"/>
                      </div>
                      <div v-show="show" class="dropdown">
                        <div v-if="i >= top_max" class="item" v-for="(o,i) in nav_top">
                          <router-link :to="o.path">
                            {{ o.page_title }}
                          </router-link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
<!--                <div class="user" v-if="!user.user_id">-->
<!--                  <router-link class="ml" :to="o.url" v-for="(o, i) in not_login_dropdown" :key="i">-->
<!--                    {{ o.name }}-->
<!--                  </router-link>-->
<!--                </div>-->
<!--                <div ref="my" v-else class="user">-->
<!--                  <div ref="own" class="own" @click="my()">-->
<!--                    <span class="own_text">我的</span>-->
<!--                    <span class="caret" v-bind:style="-->
<!--												{transform: 'rotate('+ angle2 +'deg)'}"/>-->
<!--                  </div>-->
<!--                  <div v-show="my_show" class="dropdown">-->
<!--                    <div class="my_item" v-for="(o,i) in my_nav">-->
<!--                      <router-link :to="o.url">-->
<!--                        {{ o.name }}-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                    &lt;!&ndash;                    <div class="my_item">&ndash;&gt;-->
<!--                    &lt;!&ndash;                      <div @click="to_admin()">&ndash;&gt;-->
<!--                    &lt;!&ndash;                        个人中心&ndash;&gt;-->
<!--                    &lt;!&ndash;                      </div>&ndash;&gt;-->
<!--                    &lt;!&ndash;                    </div>&ndash;&gt;-->
<!--                    <div class="my_item" @click="sign_out()">-->
<!--                      <router-link to="/">-->
<!--                        退出-->
<!--                      </router-link>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <div ref="mini" class="mini_nav" @click="open_mini()">
                  <div ref="mini" class="mini">
                    <span class="mini_text">菜单</span>
                    <span class="caret" v-bind:style="
												{transform: 'rotate('+ angle3 +'deg)'}"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<script>

import mixin from "@/mixins/component.js";

const OFFSET_HEIGHT = 60;
export default {

  mixins: [mixin],

  data() {
    return {
      top_max: 15,
      angle1: 0,
      angle2: 0,
      angle3: 0,
      visible: true,
      show: false,
      my_show: false,
      nav_top: [],
      my_nav: [
        {
          name: "我的账户",
          url: "/user/index",
        },
        {
          name: "我的收藏",
          url: "/user/collect",
        },
      ],
      not_login_dropdown: [{
        name: "登录",
        url: "/account/login",
      },
        {
          name: "注册",
          url: "/account/register",
        },
      ],
      showInput: false,
      search_val: "",
      logo: "",
      flag1:false
    };
  },

  methods: {

    to_admin() {
      let token = $.db.get("token");
      if (token) {
        location.href = "http://localhost:8080/?token=" + token;
      }
    },

    // 获取导航栏
    async get_nav_top() {
      var auth = this.$store.state.web.auth;
      if (auth.length > 0) {
        var arr = [];
        for (var i = 0; i < auth.length; i++) {
          var o = auth[i];
          if (o["position"] == "top") {
            arr.push(o);
          }
        }
        this.nav_top = arr;
      } else {
        var user_group = this.$store.state.user.user_group || $.db.get("user_group") || "游客";
        var res = await this.$get("~/api/auth/get_list", {
          user_group,
          "get": 1,
          "position": "top",
          "page": ""
        });
        if (res.result && res.result.list) {
          this.nav_top = res.result.list;
        }
      }
    },

    sign_out() {
      this.$store.commit("sign_out");
      this.$get("~/api/user/quit?", {}, (res) => {
        console.log(res);
      })
      // location.href = location.href
    },

    submit_search() {
      this.$router.push("/search?word=" + this.search_val);
      // this.$refs.navbar_toggle.$el.click();
    },

    change() {
      var _this = this;

      _this.show = !_this.show;
      var angle = _this.angle1;
      angle = angle + 180;
      if (angle === 360) {
        _this.angle1 = 0;
      } else {
        _this.angle1 = angle;
      }
    },

    my() {
      var _this = this;
      if (_this.angle3 === 180) {
        _this.$refs.nav_target.style.display = 'none';
        _this.$refs.search.style.display = 'none';
        _this.angle3 = 0;
      }
      _this.my_show = !_this.my_show;
      var angle = _this.angle2;
      angle = angle + 180;
      if (angle === 360) {
        _this.angle2 = 0
      } else {
        _this.angle2 = angle;
      }
    },

    open_mini() {
      this.flag1 = !this.flag1
      var _this = this;
      if (_this.my_show) {
        _this.my_show = false;
      }
      var angle = _this.angle3;
      angle = angle + 180;
      if (angle === 360 || !this.flag1) {
        _this.$refs.nav_target.style.display = 'none';
        _this.$refs.search.style.display = 'none';
        _this.angle3 = 0;
      } else {
        _this.$refs.nav_target.style.display = 'block';
        _this.$refs.search.style.display = 'flex';
        _this.angle3 = angle;
      }
    },

    // 滚动隐藏
    handleScroll() {
      const offsetTop = window.pageYOffset || document.documentElement.scrollTop;
      this.visible = offsetTop < OFFSET_HEIGHT;
    },

    keep(width) {
      var _this = this;
      var display = _this.$refs.nav_target.style.display;
      if (width >= 960 && display === 'none') {
        _this.$refs.nav_target.style.display = 'block';
        this.$refs.search.style.display = 'flex';
      }
    }

  },

  created() {
    this.get_nav_top();
    this.logo = document.logo
  },

  mounted() {

    window.onresize = () => {
      return (() => {
        this.keep(document.body.clientWidth);
      })();
    };
    window.addEventListener('scroll', this.handleScroll);
    document.addEventListener('mouseup', (e) => {
      const _this = this;
      let tree = _this.$refs.more;
      let my = _this.$refs.my;
      if (tree) {
        if (!tree.contains(e.target)) {
          _this.show = false;
          _this.angle1 = 0;
        }
      }
      if (my) {
        if (!my.contains(e.target)) {
          _this.my_show = false;
          _this.angle2 = 0;
        }
      }
    });
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

};
(function () {

  var bp = document.createElement('script');

  var curProtocol = window.location.protocol.split(':')[0];

  if (curProtocol === 'https') {

    bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';

  } else {

    bp.src = 'http://push.zhanzhang.baidu.com/push.js';

  }

  var s = document.getElementsByTagName("script")[0];

  s.parentNode.insertBefore(bp, s);

})();
</script>

<style scoped="scoped">

.header_warp {
  background-color: var(--color_primary);
  height: 4rem;
  color: white;
  font-weight: 600;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
}

.my_header {
  display: block;
  height: 4rem;
}

.logo {
  font-size: 1.3rem;
  float: left;
  height: 50px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav_list {
  float: left;
}

.caret {
  margin-left: 0.875rem;
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  transition: transform 0.2s;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}

.fade-enter,
.fade-leave-to {
  transform: translateY(-60px);
}

ul {
  margin: 0;
  padding: 0;
}

li {
  float: left;
  list-style: none;
}

li a {
  color: #ddd;
  height: 4rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more {
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.caret:hover,
.more:hover {
  cursor: pointer;
  color: #909399;
}

.dropdown {
  position: absolute;
  top: 5rem;
  left: -1.5rem;
  height: auto;
  background-color: white;
  width: 8rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  border-radius: 0.3rem;
  flex-direction: column;
  font-size: 0.8rem;
}

.dropdown .my_item a {
  color: #000 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
}

.dropdown .my_item a:hover {
  border-radius: 0.3rem;
  color: #fff !important;
}

.dropdown .my_item div {
  color: #000 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  cursor: pointer;
}

.dropdown .my_item div:hover {
  border-radius: 0.3rem;
  color: #fff !important;
}

.dropdown .my_item:hover {
  background-color: #909399;
}

.dropdown .item a {
  color: #000 !important;
  margin: auto;
  height: 3rem;
  width: 100%;
}

.dropdown .item a:hover {
  border-radius: 0.3rem;
  color: #fff !important;
  background-color: #909399;
}

.user {
  position: relative;
  margin-left: 2rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: right;
  width: 6rem;
  margin-right: 1rem;
}

.own:hover {
  color: #909399;
  cursor: pointer;
}

.search-box {
  width: 15rem;
  height: 4rem;
  float: right;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.search_pic {
  width: 1rem;
  height: 1rem;
}

.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background: #909399;
  border: 0;
  opacity: 1;
  border-radius: 0 4px 4px 0;
}

.search-btn:hover {
  cursor: pointer;
}

.search-txt {
  height: 2.5rem;
  background: #FFFFFF;
  border: 1px solid #ddd;
  opacity: 1;
  border-right: none;
  border-radius: 4px 0 0 4px;
  width: 12.5rem
}

.mini_nav {
  display: none;
}

@media (max-width: 1200px) {

  .search-box {
    width: 10rem;
  }

  .search-txt {
    width: 7.5rem
  }

  .user {
    margin-left: 0;
  }
}

@media (max-width: 1081px) {

  .nav_list {
    color: var(--color_primary);
    padding-top: 3.5rem;
    background-color: #e70012;
    display: none;
    position: absolute;
    top: 5rem;
    right: 4rem;
    width: 11rem;
    /* height: 20rem; */
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
  }

  .nav_list ul {
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-content: center;
  }

  .dropdown {
    left: -2rem;
  }

  .search-box {
    position: absolute;
    top: 5rem;
    right: 7rem;
    display: none;
  }


  .mini_nav {
    float: right;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown {
    left: -4rem;
    width: 12rem;
  }

  .user .dropdown {
    left: -3rem;
    width: 8rem;
  }

  .page_header .header_warp .logo {
    background: url(../../assets/images/logow.png) no-repeat;
    background-size: 100% 100%;
    font-size: 24px !important;
    color: #FFFFFF;
    width: 25%;
    position: absolute;
    top: 0.6rem;
    left: -2rem;
    /* border: 3px dotted #FFFFFF; */
    /* background: #ff2e2e !important; */
    height: 55%;
    margin-top: 0.2rem;
    /* border-radius: 10px; */
    /* border: 1px dashed #fff; */
    margin-right: 20px;
  }
  .page_header .header_warp .user a {
    width: 2rem;
    text-align: center;
  }
  .page_header .header_warp .user {
    position: fixed;
  }
}

</style>
